import { Avatar, Button, Stack, styled, TextField } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import FormControl from "@mui/material/FormControl";
import { useSearchParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid } from "@mui/x-data-grid";
import Select from "@mui/material/Select";

import classes from "../../src/Style/Details.module.css";
import { FormDialog } from "../Components/FormDialog";
import Dialogue from "../Components/Dialogue";
import { useFetch } from "../hooks/useFetch";
import Wrapper from "../Components/Wrapper";
import { generateQueryString } from "../tools/utils";

function Details() {
  const { Fetch } = useFetch();

  const [userID] = useSearchParams();

  const [userData, setUserData] = useState({});

  const [selfie, setselfie] = useState("");
  const [document, setdocument] = useState("");
  const [walletdata, setwalletdata] = useState([]);
  const [openDialog, setopenDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const [type, settype] = useState("");

  const [sendcurrency, setsendcurrency] = useState("USDC");
  const [sendamount, setsendamount] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showCurrencies, setshowcurrencies] = useState(false);
  const [page, setPage] = useState(0);
  const [transactionsCount, setTransactionsCount] = useState(0);

  let pageTimer = useRef(null);
  const divRef = useRef(null);

  async function Load_data() {
    setLoading(true);

    let res = await Fetch("get", `users/${userID.get("id")}`);
    setUserData({
      id: res.id,
      name: res.name + " " + res.surname,
      phoneNumber: res.phone_no,
      DOB: res.birth_date,
      gender: res.gender,
      email: res.email,
      country: res.country_code,
      state: res.address?.state,
      city: res.address?.city,
      address: res.address?.address,
      zip: res.address?.zip,
      idNumber: res.document_id,
      idType: res.document?.type,
      issuingCountry: res.address?.country,
      creationDate: res.document?.createdAt.split("T", [1]),
      status: res.status,
      avatar: res.avatar,
      kycStatus: res.kyc_status,
      walletID: res.wallets ? res.wallets[0].id : "",
      userHandle: res.user_handle,
      walletBalance: res.wallet,
    });

    let doc = await Fetch("post", "users/documents", {
      selfie: res.selfie || undefined,
      document: res.document || undefined,
    });
    setselfie(doc.selfie || "");
    setdocument(doc.document || "");

    setLoading(false);

    // getTransactions();
  }

  const getTransactions = async () => {
    setTableLoading(true);
    let filters = {
      page: page,
      userId: userID.get("id"),
    };
    let data = await Fetch(
      "get",
      `transactions?${generateQueryString(JSON.parse(JSON.stringify(filters)))}`
    );
    console.log("data", data);
    setTransactionsCount(data.totalCount);
    setwalletdata(data.data);
    setTableLoading(false);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleCopy = () => {
    const text = divRef.current.innerText;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Wallet address copied");
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
      });
  };

  const sendMoney = async () => {
    setLoading(true);

    let res = await Fetch("post", "payment/send", {
      userID: parseInt(userData.id),
      amount: sendamount,
      currency: sendcurrency,
    });

    if (res) {
      window.location.reload(false);
    } else {
      alert(res.error);
    }

    setLoading(false);
  };

  useEffect(() => {
    getTransactions();
  }, [page]);
  useEffect(() => {
    Load_data();
  }, []);

  return (
    <Wrapper loading={loading}>
      <div>
        {openDialog ? (
          <Dialogue
            open={true}
            onClose={() => {
              setopenDialog(false);
            }}
            type={type}
            id={[userData.id]}
          ></Dialogue>
        ) : (
          ""
        )}
      </div>

      <section className={classes.BodySectiond}>
        <div className={classes.DataTableHeader}>
          <h2>Details</h2>
          <div className={classes.ButtonsSections}>
            <Stack direction="row" spacing={1}>
              {userData.kycStatus == "doc_kyc_inProgress" &&
              userData.country.toUpperCase() != "US" ? (
                <Stack direction="row" spacing={1}>
                  <VerifyButton
                    size="small"
                    variant="contained"
                    onClick={verify_button}
                  >
                    Verify
                  </VerifyButton>
                  <RejectDocumentsButton
                    size="small"
                    variant="contained"
                    onClick={reject_document_button}
                  >
                    Reject Documents
                  </RejectDocumentsButton>
                  <SendMessageButton
                    size="small"
                    variant="contained"
                    onClick={() => {
                      settype("notification");
                      send_message_button();
                    }}
                  >
                    Send Notification
                  </SendMessageButton>
                  <DeactivateButton
                    size="small"
                    variant="outlined"
                    className={classes.btn}
                    color="error"
                    onClick={deactivate_button}
                  >
                    Deactivate
                  </DeactivateButton>
                </Stack>
              ) : userData.status == "D" ? (
                <SendMessageButton
                  size="small"
                  variant="contained"
                  onClick={() => {
                    settype("notification");
                    send_message_button();
                  }}
                >
                  Send Notification
                </SendMessageButton>
              ) : (
                <Stack direction="row" spacing={1}>
                  <SendMessageButton
                    size="small"
                    variant="contained"
                    onClick={() => {
                      settype("notification");
                      send_message_button();
                    }}
                  >
                    Send Notification
                  </SendMessageButton>
                  <DeactivateButton
                    size="small"
                    variant="outlined"
                    className={classes.btn}
                    color="error"
                    onClick={deactivate_button}
                  >
                    Deactivate
                  </DeactivateButton>
                  <SendMessageButton
                    size="small"
                    variant="contained"
                    onClick={togglePopup}
                  >
                    Send Money
                  </SendMessageButton>
                </Stack>
              )}
            </Stack>
          </div>
        </div>
        <div className={classes.DataSection}>
          <div className={classes.UserData}>
            <div className={classes.User}>
              {userData.status === "A" ? (
                <span
                  style={{
                    color: "#2D90EC",
                    alignSelf: "flex-start",
                    marginLeft: "2%",
                  }}
                >
                  Verified{" "}
                </span>
              ) : userData.kycStatus === "doc_kyc_inProgress" ? (
                <span
                  style={{
                    color: "#64c4b6",
                    alignSelf: "flex-start",
                    marginLeft: "2%",
                  }}
                >
                  Pending{" "}
                </span>
              ) : userData.status === "I" ? (
                <span
                  style={{
                    color: "#747474",
                    alignSelf: "flex-start",
                    marginLeft: "2%",
                  }}
                >
                  Not Active{" "}
                </span>
              ) : userData.status === "D" ? (
                <span
                  style={{
                    color: "#60100B",
                    alignSelf: "flex-start",
                    marginLeft: "2%",
                  }}
                >
                  Deactivated{" "}
                </span>
              ) : (
                ""
              )}

              <Avatar src={selfie ? selfie : "/broken-image.jpg"} id="avatar" />
              <span id="ID_number">ID number: {userData.id}</span>
            </div>
            <div className={classes.UserDetails}>
              <div className={classes.Details_title}>User Details</div>
              <div className={classes.UserDetails_Data}>
                <div className={classes.UserDetails_Data_Row}>
                  <span className={classes.UserDetails_Data_Title}>Name</span>
                  <span className={classes.UserDetails_Data_Result}>
                    {userData.name}
                  </span>
                </div>
                <div className={classes.UserDetails_Data_Row}>
                  <span className={classes.UserDetails_Data_Title}>
                    Phone Number
                  </span>
                  <span
                    style={{ color: "blue", cursor: "pointer" }}
                    className={classes.UserDetails_Data_Result}
                    onClick={() => {
                      settype("sms");
                      send_message_button();
                    }}
                  >
                    {userData.phoneNumber}
                  </span>
                </div>
                <div className={classes.UserDetails_Data_Row}>
                  <span className={classes.UserDetails_Data_Title}>DOB</span>
                  <span className={classes.UserDetails_Data_Result}>
                    {userData.DOB}
                  </span>
                </div>
                <div className={classes.UserDetails_Data_Row}>
                  <span className={classes.UserDetails_Data_Title}>Gender</span>
                  <span className={classes.UserDetails_Data_Result}>
                    {userData.gender == "M"
                      ? "Male"
                      : userData.gender == "F"
                      ? "Female"
                      : ""}
                  </span>
                </div>
                <div className={classes.UserDetails_Data_Row}>
                  <span className={classes.UserDetails_Data_Title}>E-mail</span>
                  <span
                    style={{ color: "blue", cursor: "pointer" }}
                    className={classes.UserDetails_Data_Result}
                    onClick={() => {
                      settype("email");
                      send_message_button();
                    }}
                  >
                    {userData.email}
                  </span>
                </div>
              </div>
            </div>
            <div className={classes.Address}>
              <div className={classes.Details_title}>Address</div>
              <div className={classes.UserDetails_Data}>
                <div className={classes.UserDetails_Data_Row}>
                  <span className={classes.UserDetails_Data_Title}>
                    Country
                  </span>
                  <span className={classes.UserDetails_Data_Result}>
                    {userData.country}
                  </span>
                </div>
                <div className={classes.UserDetails_Data_Row}>
                  <span className={classes.UserDetails_Data_Title}>State</span>
                  <span className={classes.UserDetails_Data_Result}>
                    {userData.state}
                  </span>
                </div>
                <div className={classes.UserDetails_Data_Row}>
                  <span className={classes.UserDetails_Data_Title}>City</span>
                  <span className={classes.UserDetails_Data_Result}>
                    {userData.city}
                  </span>
                </div>
                <div className={classes.UserDetails_Data_Row}>
                  <span className={classes.UserDetails_Data_Title}>
                    Address
                  </span>
                  <span className={classes.UserDetails_Data_Result}>
                    {/* {userData.address} */}
                  </span>
                </div>
                <div className={classes.UserDetails_Data_Row}>
                  <span className={classes.UserDetails_Data_Title}>Zip</span>
                  <span className={classes.UserDetails_Data_Result}>
                    {userData.zip}
                  </span>
                </div>
              </div>
            </div>
            <div className={classes.Documents}>
              <div className={classes.Details_title}>Provided Documents</div>
              <div className={classes.UserDetails_Data}>
                <div className={classes.UserDetails_Data_Row}>
                  <span className={classes.UserDetails_Data_Title}>
                    ID Type
                  </span>
                  <span className={classes.UserDetails_Data_Result}>
                    {userData.idType ? userData.idType : ""}
                  </span>
                </div>
                <div className={classes.UserDetails_Data_Row}>
                  <span className={classes.UserDetails_Data_Title}>
                    Issuing Country
                  </span>
                  <span className={classes.UserDetails_Data_Result}>
                    {userData.issuingCountry}
                  </span>
                </div>
                <div className={classes.UserDetails_Data_Row}>
                  <span className={classes.UserDetails_Data_Title}>
                    ID Number
                  </span>
                  <span className={classes.UserDetails_Data_Result}>
                    {userData.idNumber}
                  </span>
                </div>
                <div className={classes.UserDetails_Data_Row}>
                  <span className={classes.UserDetails_Data_Title}>
                    Creation Date
                  </span>
                  <span className={classes.UserDetails_Data_Result}>
                    {userData.creationDate}
                  </span>
                </div>
                <div className={classes.UserDetails_Data_Row}>
                  <span className={classes.UserDetails_Data_Title}>ID</span>
                  {document ? (
                    <Button
                      size="small"
                      onClick={() => window.open(document)}
                      className={classes.UserDetails_Data_Result}
                      style={{ color: "#64c4b6" }}
                    >
                      View Document
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.UserWallet}>
            <div
              style={{
                width: "96%",
                display: "flex",
                alignItems: "center",
                marginLeft: "2%",
                height: "5%",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <span>
                Public Key :
                <span
                  style={{ fontSize: 11 }}
                  id="wallet_address"
                  ref={divRef}
                  onClick={handleCopy}
                >
                  {" "}
                  {userData.userHandle}
                </span>
              </span>

              <div
                onClick={() => {
                  setshowcurrencies(!showCurrencies);
                }}
              >
                {" "}
                Wallet Balance ▾
              </div>
              <div
                className={classes.currencies_div}
                style={{
                  visibility: !showCurrencies ? "hidden" : "visible",
                }}
              >
                {userData.walletBalance ? (
                  userData.walletBalance.map((item, index) => (
                    <div key={index}>
                      <span>{Number(item.balance).toFixed(2)}</span>{" "}
                      <span>{!item.asset_code ? "XLM" : item.asset_code}</span>
                    </div>
                  ))
                ) : (
                  <div>
                    <span>Wallet data does not exist</span>{" "}
                  </div>
                )}
              </div>
            </div>
            <div style={{ height: "95%" }}>
              <DataGrid
                columns={[
                  {
                    field: "createdAt",
                    flex: 1,
                    headerName: "Date",
                    renderCell: (row) => (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{row.value.split(",")[0]} </span>
                        <span>{row.value.split(",")[1]}</span>
                      </div>
                    ),
                  },
                  {
                    field: "id",
                    flex: 1,
                    headerName: "ID",
                    align: "center",
                  },
                  {
                    field: "amount",
                    flex: 1,
                    headerName: "Sent Amount",
                    renderCell: (row) =>
                      row.value
                        ? `${row.value?.toFixed(2)} ${
                            row.row.sentCurrency?.name || "USD"
                          }`
                        : "TBD",
                  },
                  {
                    field: "received_amount",
                    flex: 1,
                    headerName: "Received Amount",
                    renderCell: (row) =>
                      row.value
                        ? `${row.value?.toFixed(2)} ${
                            row.row.receivedCurrency?.name || "USD"
                          }`
                        : "TBD",
                  },
                  {
                    field: "request_amount",
                    flex: 1,
                    headerName: "Requested Amount",
                    renderCell: (row) =>
                      row.value
                        ? `${row.value?.toFixed(2) || null} ${
                            row.row.receivedCurrency?.name || "USD"
                          }`
                        : "null",
                  },
                  {
                    field: "type",
                    flex: 1,
                    headerName: "Type",
                    renderCell: (row) => (
                      <span>
                        {row.value === "C"
                          ? "P2P"
                          : row.value === "B"
                          ? "Bank Operation"
                          : row.value === "Q"
                          ? "By Qr Code"
                          : row.value === "M"
                          ? "MoneyGram"
                          : row.value === "D"
                          ? "Card"
                          : row.value === "S"
                          ? "Swap"
                          : row.value === "E"
                          ? "External Wallet"
                          : row.value === "P"
                          ? "Connector"
                          : "--- Data Unavailable"}
                      </span>
                    ),
                  },
                  {
                    field: "transaction_type",
                    flex: 1,
                    headerName: "Peers",
                    renderCell: (row) => (
                      <span>
                        {row.row.type === "C"
                          ? userData.id !== row.row.sender_id
                            ? row.row.sender &&
                              row.row.sender.name &&
                              row.row.sender.surname
                              ? row.row.sender.name +
                                " " +
                                row.row.sender.surname
                              : "----No NAME----"
                            : row.row.receiver &&
                              row.row.receiver.name &&
                              row.row.receiver.surname
                            ? row.row.receiver.name +
                              " " +
                              row.row.receiver.surname
                            : "----No NAME----"
                          : row.row.type === "B"
                          ? "Bank Operation"
                          : row.row.type === "Q"
                          ? userData.id !== row.row.sender_id
                            ? row.row.sender &&
                              row.row.sender.name &&
                              row.row.sender.surname
                              ? row.row.sender.name +
                                " " +
                                row.row.sender.surname
                              : "----No NAME----"
                            : row.row.receiver &&
                              row.row.receiver.name &&
                              row.row.receiver.surname
                            ? row.row.receiver.name +
                              " " +
                              row.row.receiver.surname
                            : "----No NAME----"
                          : row.row.type === "M"
                          ? "MoneyGram"
                          : row.row.type === "D"
                          ? "Card"
                          : row.row.type === "S"
                          ? "Swap"
                          : row.row.type === "E"
                          ? "External Wallet"
                          : row.row.type === "P"
                          ? userData.id !== row.row.sender_id
                            ? row.row.sender &&
                              row.row.sender.name &&
                              row.row.sender.surname
                              ? row.row.sender.name +
                                " " +
                                row.row.sender.surname
                              : "----No NAME----"
                            : row.row.receiver &&
                              row.row.receiver.name &&
                              row.row.receiver.surname
                            ? row.row.receiver.name +
                              " " +
                              row.row.receiver.surname
                            : "----No NAME----"
                          : "--- Data Unavailable"}
                      </span>
                    ),
                  },
                  {
                    field: "status",
                    flex: 1,
                    headerName: "Status",
                    renderCell: (row) =>
                      row.value === "S" ? (
                        <span style={{ color: "#0b3758" }}>Complete</span>
                      ) : row.value === "P" ? (
                        <span style={{ color: "#64c4b6" }}>Pending</span>
                      ) : row.value === "R" ? (
                        <span style={{ color: "#60100B" }}>Rejected</span>
                      ) : row.value === "C" ? (
                        <span style={{ color: "#747474" }}>Canceled</span>
                      ) : row.value === "F" ? (
                        <span style={{ color: "red" }}>Failed</span>
                      ) : (
                        ""
                      ),
                  },
                ]}
                rows={walletdata}
                loading={tableLoading}
                pageSize={15}
                rowCount={transactionsCount}
                paginationMode="server"
                onPageChange={(newPage) => {
                  clearTimeout(pageTimer.current);
                  setTableLoading(true);
                  pageTimer.current = setTimeout(() => {
                    setPage(newPage);
                  }, 1500);
                }}
              ></DataGrid>
            </div>
          </div>
        </div>
      </section>

      <FormDialog
        show={showPopup}
        onClose={() => {
          setShowPopup(false);
        }}
        hideDialogActions
        dialogTitle="Send Money"
      >
        <div className={classes.dialogContainer}>
          <TextField
            label="User ID"
            className={classes.textField}
            value={userData.id || ""}
          />
          <div className={classes.amountContainer}>
            <TextField
              label="Amount"
              className={classes.textField}
              style={{ flex: 1 }}
              value={sendamount}
              onChange={(e) => setsendamount(e.target.value)}
            />
            <FormControl>
              <InputLabel id="demo-simple-select-label">Currency</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sendcurrency}
                label="Currency"
                onChange={(e) => setsendcurrency(e.target.value)}
                className={classes.textField}
              >
                {userData.walletBalance &&
                  userData.walletBalance.map((item, index) => (
                    <MenuItem value={item.asset_code || item.asset_type}>
                      {(item.asset_code || item.asset_type) === "native"
                        ? "XLM"
                        : item.asset_code || item.asset_type}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <Button
            variant="contained"
            className={classes.buttonStyle}
            style={{ height: "max-content" }}
            onClick={sendMoney}
            disabled={!userData.id || !sendcurrency || !sendamount}
          >
            Send
          </Button>
        </div>
      </FormDialog>
    </Wrapper>
  );

  function deactivate_button() {
    confirmAlert({
      title: "Confirm to Deactivate user",
      message: "Are you sure that you want to de-activate this user ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deactivate_user();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  async function deactivate_user() {
    await Fetch("delete", `users/${userID.get("id")}/deactivate`);
    Load_data();
  }

  function reject_document_button() {
    confirmAlert({
      title: "Confirm to Reject Documents",
      message: "Are you sure that you want notify this user ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            reject_document();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  async function reject_document() {
    await Fetch("post", `users/${userID.get("id")}/status`, { status: "P" });
    Load_data();
  }

  function verify_button() {
    confirmAlert({
      title: "Confirm to Verify user",
      message: "Are you sure that you want to verify this user ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            verify();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  async function verify() {
    await Fetch("post", `users/${userID.get("id")}/status`, { status: "A" });
    Load_data();
  }

  function send_message_button() {
    setopenDialog(true);
  }
}

export default Details;

const DeactivateButton = styled(Button)({
  color: "#FF4C4C",
  fontSize: 12,
  padding: "6px 12px",
  border: "1px solid #FFC4C4",
  boxSizing: "border-box",
  borderRadius: 10,
  lineHeight: 1.5,
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
    border: "1px solid #FFC4C4",
  },
});

const SendMessageButton = styled(Button)({
  color: "#313131",
  fontSize: 12,
  padding: "6px 12px",
  border: "1px solid #fff",
  boxSizing: "border-box",
  borderRadius: 10,
  lineHeight: 1.5,
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
    border: "1px solid #fff",
  },
});

const RejectDocumentsButton = styled(Button)({
  color: "#fff",
  fontSize: 12,
  borderRadius: 10,
  lineHeight: 1.5,
  backgroundColor: "#64c4b6",
  "&:hover": {
    backgroundColor: "#64c4b6",
  },
});

const VerifyButton = styled(Button)({
  color: "#fff",
  fontSize: 12,
  borderRadius: 10,
  lineHeight: 1.5,
  backgroundColor: "#0B3758",
  "&:hover": {
    backgroundColor: "#0B3758",
  },
});
